import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import Layout from '../components/Layout'; // Assuming you have a layout component
import { getCourseById, updateCourseById, uploadFlag } from '../service/service'; // Import the necessary services
import _ from 'lodash'
const EditCourse = () => {
  const { id } = useParams(); // Get the course ID from the URL parameters
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(true);
  const [originalUnitsArray, setOriginalUnitsArray] = useState([]);
  const [originalCourse, setOriginalCourse ] = useState(null)
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");


  const fetchCourse = async () => {
    try {
    const fetchedCourse = await getCourseById(id);
    setCourse(fetchedCourse.data);
    setOriginalCourse(fetchedCourse.data)
    setOriginalUnitsArray(fetchedCourse.data.units); // Store original units
    setLoading(false);
    } catch (error) {
      console.log("Failed to fetch course:", error);
      setLoading(false);
    }
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false); // Close the toast when done
  };


  useEffect(() => {
    fetchCourse();
  }, [id]);

  const handleUpdate = async () => {
    if (course) {
      // Prepare updated course data
      const updatedData = {
        name: course.name, // Always send the course name
        description: course.description, // Always send the course description
        units: [],
      };
  
      // Compare current units with original units
      const modifiedUnits = _.differenceWith(course.units, originalUnitsArray, _.isEqual);
  

      // Prepare units for update
      updatedData.units = modifiedUnits.map(unit => ({
        _id: unit._id,
        isBonus: unit.isBonus,
        name: unit.name,
      }));

  
      // Make API call only if there are modified units
      // if (updatedData.units.length > 0 || originalCourse?.name !== course.name || originalCourse?.description !== course.description) {
        console.log("Updated Course data", updatedData)
        try {
        const res  =await updateCourseById(id, updatedData);
        await fetchCourse();
        setMessage(res.message); // Set the success message
        setOpen(true); // Open the toast
        } catch (error) {
        setMessage('Error occurred while updating Course');
        setSeverity('error'); // Red toast for error
        }
      // }
    }
  };

  const handleEditUnit = (unitId) => {
    navigate(`/course/${id}/units/${unitId}/edit`); // Navigate to the Unit Edit Page with unitId in the URL
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);

      // Generate a preview URL
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
    } else {
      alert("Please select a valid image file.");
    }
  };


  const handleFlagUpload = async () => {
    if (!selectedFile) {
      alert("Please select an image file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("courseId", id);
    
    try {
      const response = await uploadFlag(formData)

      if (response.ok) {
        alert("Image uploaded successfully!");
        setSelectedFile(null);
        setPreviewUrl("");
      } else {
        alert("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  if (loading) return <div>Loading...</div>; // Loading state

  return (
    <Layout>
      <Box sx={{ padding: 2 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" gutterBottom>
            Edit Course
          </Typography>
          <div className='image-container'>
            <img src={course.image} width="100" />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ margin: "20px 0" }}
            />
            {selectedFile && <Button variant="contained" color='info' onClick={handleFlagUpload}>Upload Flag</Button>}
            </div>
        </div>

        <TextField
          label="Course Name"
          variant="outlined"
          fullWidth
          value={course.name}
          onChange={(e) => setCourse({ ...course, name: e.target.value })}
          margin="normal"
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={course.description}
          onChange={(e) => setCourse({ ...course, description: e.target.value })}
          margin="normal"
        />

        <Typography variant="h6" gutterBottom>
          Units
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Is Bonus</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {course.units.map((unit) => (
                <TableRow key={unit._id}>
                  <TableCell>{unit.order}</TableCell> {/* Display order without editing */}
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={unit.name}
                      onChange={(e) => {
                        const updatedUnits = course.units.map((u) =>
                          u._id === unit._id ? { ...u, name: e.target.value } : u
                        );
                        setCourse({ ...course, units: updatedUnits });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={unit.isBonus}
                          onChange={(e) => {
                            const updatedUnits = course.units.map((u) =>
                              u._id === unit._id ? { ...u, isBonus: e.target.checked } : u
                            );
                            setCourse({ ...course, units: updatedUnits });
                          }}
                        />
                      }
                      label="Is Bonus"
                    />
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEditUnit(unit._id)}>Edit</Button> {/* Edit button for future functionality */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ marginTop: 2 }}>
          Update Course
        </Button>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={4000} // Toast will auto close after 4 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position of the toast
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default EditCourse;
