import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import UnitGroup from '../components/ReviewVoiceNote/UnitGroup';
import { getAllUviForAdmin } from '../service/service';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';

const VoiceNoteTable = () => {
  const {  courseId, courseName } = useParams(); // Get the course ID from the URL parameters
  const [voices, setVoices] = useState([]);
  
  const getAllVoices = async () => {
    try {
      const result = await getAllUviForAdmin(courseId, courseName);
      setVoices(result);
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    getAllVoices();
  }, []);
  
  const handleDelete = (voiceId) => {
    getAllVoices();
  };

  const groupedVoices = voices?.reduce((acc, voice) => {
    const { parentUnitId } = voice;
    if (!acc[parentUnitId.name]) acc[parentUnitId.name] = [];
    acc[parentUnitId.name].push(voice);
    return acc;
  }, {});

  return (
    <Layout>
      <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
        Review Your Voice Notes Here!
      </Typography>
      {Object.keys(groupedVoices).map((unitName) => (
        <UnitGroup handleDelete ={handleDelete } setVoices={setVoices} courseName={courseName } key={unitName} unitName={unitName} voices={groupedVoices[unitName]} />
      ))}
    </Layout>
  );
};

export default VoiceNoteTable;
