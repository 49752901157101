import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider, CssBaseline } from '@mui/material';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import ReviewVoiceNotes from './pages/ReviewVoiceNotes'; // Import Review Voice Notes
import PrivateRoute from './components/PrivateRoutes'; 
import EditCourse  from './pages/EditCourse';// Adjusted import name
import UnitEditPage from './pages/EditUnit';
import theme from './theme'; // Assuming theme.js exports the theme
import VoiceNoteTable from './pages/VoiceNoteTable';
import CreateCourse from './pages/CreateCourse';


import './App.css';
const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route 
              path="/dashboard" 
              element={<PrivateRoute element={<Dashboard />} />} 
            />
            <Route 
              path="/home" 
              element={<PrivateRoute element={<Dashboard />} />} 
            />
            <Route 
              path="/review-voice-notes" 
              element={<PrivateRoute element={<ReviewVoiceNotes />} />} 
            />

            <Route 
              path="/voice-notes/:courseId/:courseName" 
              element={<PrivateRoute element={<VoiceNoteTable />} />} 
            />

            <Route 
              path="/edit/:id" 
              element={<PrivateRoute element={<EditCourse />} />} 
            />

            <Route 
              path="course/:courseId/units/:unitId/edit" 
              element={<PrivateRoute element={<UnitEditPage />} />} 
            />

            <Route 
              path="/create-course" 
              element={<PrivateRoute element={<CreateCourse />} />} 
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
