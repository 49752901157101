import React, { useContext, useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const drawerWidth = 240;

const DrawerMenu = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // State to manage selected menu item

  useEffect(() => {
    // Set selectedIndex based on the current location
    if (location.pathname === '/home') {
      setSelectedIndex(0);
    } else if (location.pathname === '/review-voice-notes') {
      setSelectedIndex(1);
    }
  }, [location]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    navigate('/'); // Redirect to login page
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <h6 style={{ flexGrow: 1 }}>Menu</h6>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
      >
        <List>
          <ListItem 
            button 
            onClick={() => {
              setSelectedIndex(0);
              navigate('/home');
            }}
            selected={selectedIndex === 0} // Highlight if selected
          >
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem 
            button 
            onClick={() => {
              setSelectedIndex(1);
              navigate('/review-voice-notes');
            }}
            selected={selectedIndex === 1} // Highlight if selected
          >
            <ListItemText primary="Review Voice Notes" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main style={{ flexGrow: 1, padding: '24px' }}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default DrawerMenu;
