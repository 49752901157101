import React, { useState, useContext, useEffect } from 'react';
import { Box, Paper, TextField, Button, Typography } from '@mui/material';
import { loginAdmin } from '../service/service';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // This effect runs after the component mounts
    const inputs = document.querySelectorAll('input:-webkit-autofill');
    inputs.forEach(input => {
      input.parentElement.classList.add('autofilled');
    });
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await loginAdmin(email, password);
      const { token } = response;
      localStorage.setItem('token', token);
      login(token);
      navigate('/dashboard');
    } catch (err) {
      setError(err.message || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'primary.main',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '2rem',
          width: '300px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Admin Login
        </Typography>
        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, -9px) scale(0.75)',
              background: 'white',
              padding: '0 4px',
            },
            '& .MuiInputBase-root.Mui-focused .MuiInputLabel-root': {
              color: 'primary.main',
            },
            '& .autofilled .MuiInputLabel-root': {
              transform: 'translate(14px, -9px) scale(0.75)',
              background: 'white',
              padding: '0 4px',
            },
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, -9px) scale(0.75)',
              background: 'white',
              padding: '0 4px',
            },
            '& .MuiInputBase-root.Mui-focused .MuiInputLabel-root': {
              color: 'primary.main',
            },
            '& .autofilled .MuiInputLabel-root': {
              transform: 'translate(14px, -9px) scale(0.75)',
              background: 'white',
              padding: '0 4px',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
      </Paper>
    </Box>
  );
};

export default LoginPage;