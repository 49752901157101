import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ token: null });

  // Load token from localStorage on mount
  useEffect(() => {
    const token = localStorage.getItem('token'); // Check localStorage for token
    if (token) {
      setAuthState({ token }); // Set token in state if it exists
    }
  }, []);

  // Function to log in
  const login = (token) => {
    setAuthState({ token }); // Update auth state
    localStorage.setItem('token', token); // Store token in localStorage
  };

  // Function to log out
  const logout = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    setAuthState({ token: null }); // Update auth state
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
const useAuthContext = () => {
  return useContext(AuthContext);
};
export {useAuthContext }