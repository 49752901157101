import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton, TableCell, TableRow, TextField, Button, Snackbar, Alert
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getUserSubscriptionForAdmin, reviewQuestion } from '../../service/service';
import { formatDate } from '../../utils/helperFunction';
import { Howl } from 'howler';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import StarIcon from '@mui/icons-material/Star';

const VoiceRow = ({ voice, currentPlayingId, setCurrentPlayingId, setVoices, handleDelete }) => {
  const [audioElement, setAudioElement] = useState(null);
  const [voiceStatus, setVoiceStatus] = useState(voice.expertAction); // Initial status from voice data
  const [remark, setRemark] = useState(voice.expertRemark || '');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const ffmpegRef = useRef(new FFmpeg());  // Store ffmpeg instance in a ref
  const [transcodedUrl, setTranscodedUrl] = useState(voice.answer);
  const [isPremium, setIsPremium] = useState(false);


  const getUserPlan = async () => {
    console.log(voice.userId);
    const response = await getUserSubscriptionForAdmin(voice.userId._id);

    if (response.data.planName === 'Free Plan') {
      setIsPremium(false);
    } else {
      setIsPremium(true);
    }
  }

  useEffect(() => {
    getUserPlan();
  }, [isPremium]);
  

  const transcode = async (audioUrl) => {

    const ffmpeg = ffmpegRef.current;
    await ffmpeg.load();

    try {
      await ffmpeg.writeFile('input.m4a', await fetchFile(audioUrl));
      await ffmpeg.exec(['-i', 'input.m4a', 'output.mp3']);
      const data = await ffmpeg.readFile('output.mp3');
      const url = URL.createObjectURL(new Blob([data], { type: 'audio/mp3' })); // Store transcoded URL in state
      setTranscodedUrl(url);
      //return URL.createObjectURL(url);
    } catch (error) {
      console.error('Transcoding failed:', error);
      return null;
    }
  };

  // Leaving this function as it is
  const handlePlayPause = async (audioUrl) => {
    if (currentPlayingId === voice._id) {
      audioElement?.pause();
      setCurrentPlayingId(null);
    } else {
      if (audioElement) audioElement.stop(); // Stop any currently playing audio

      const transcodedUrl = await transcode(audioUrl); // Transcode before playback
      if (!transcodedUrl) {
        console.error('Failed to transcode audio');
        return;
      }

      const newAudio = new Howl({
        src: [transcodedUrl],
        format: ['mp3']
      });

      newAudio.play();
      setAudioElement(newAudio);
      setCurrentPlayingId(voice._id);

      newAudio.on('end', () => {
        setCurrentPlayingId(null);
      });
    }
  };

  const handleSelectStatus = (status) => {
    setVoiceStatus(status);
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const result = await reviewQuestion(voice._id, voiceStatus, remark);
      setOpen(true);
      setSeverity('success');
      setMessage(result.message);
      handleDelete(voice._id);
    } catch (error) {
      setSeverity('error');
      console.error(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <TableRow sx={{ backgroundColor: isPremium ? 'lightyellow' : 'transparent' }}>
      <TableCell>{voice.order}
      {isPremium && <StarIcon sx={{ color: 'gold',stroke: "#FF5100FF", strokeWidth: 1}} />}
      </TableCell>
      <TableCell>
        <div>{voice?.userId?.email}</div>
        <div><b>Speakig - </b>{voice?.userId?.primaryAccent}</div>
      </TableCell>
      <TableCell>{formatDate(voice?.createdAt)}</TableCell>
      <TableCell sx={{ width: '30%' }}>{voice.originalQuestionId.question}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() => transcode(voice.answer)}
        >
          Transcode
        </Button>
        <p></p>
        <audio src={transcodedUrl} controls />
    </TableCell>
      <TableCell>
        <IconButton
          onClick={() => handleSelectStatus('correct')}
          color={voiceStatus === 'correct' ? 'primary' : 'default'}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={() => handleSelectStatus('incorrect')}
          color={voiceStatus === 'incorrect' ? 'error' : 'default'}
        >
          <CloseIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        {
          isPremium ? (
            <TextField
              variant="outlined"
              placeholder="Add Remark"
              size="small"
              value={remark}
              onChange={handleRemarkChange}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                },
              }}
            />) : (/* <TextField
              variant="outlined"
              placeholder="Add Remark"
              size="small"
              value={remark}
              onChange={handleRemarkChange}
            /> */
            null
          )
        }
      </TableCell>
      <TableCell>
        {
          isPremium ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={(!remark.trim() || voiceStatus === 'pending')}
            >
              Submit
            </Button>) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={voiceStatus === 'pending'}
            >
              Submit
            </Button>)
        }

      </TableCell>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </TableRow>
  );
};

export default VoiceRow;
