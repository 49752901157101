import React, { useState } from "react";
import * as XLSX from "xlsx";
import Layout from '../components/Layout';
import {
  Button,
  CircularProgress
} from '@mui/material';
import { uploadExcelForCourse, linkAudioForCourse } from '../service/service';
import { BlobServiceClient } from "@azure/storage-blob";
// import CheckIcon from '@mui/icons-material/Check';
import CheckIcon from '@mui/icons-material/CheckCircle';
// Azure Storage Account details
const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN; // Provide SAS token for secure upload
// const containerUrl = "https://accentify.blob.core.windows.net/course5-tc";


const ExcelUploader = () => {
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [excelRowError, setExcelRowError] = useState([]);
  const [success, setCourseSuccess] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [containerUrl, setContainerUrl] = useState("")
  const [courseId, setCourseId] = useState("");
  const [containerName, setContainerName] = useState("")
  const [uploadingFileLoader, setUploadingFileLoader] = useState(false);
  const [uploadMsg, setUploadMsg] = useState("")
  const [courseCompleted, setCourseCompleted] = useState(false);

  async function uploadFilesToBlobStorage(files) {
    try {
      // Create a BlobServiceClient for the container
      // const containerClient = new BlobServiceClient(`${containerUrl}?${sasToken}`).getContainerClient();
  
      const blobServiceClient = new BlobServiceClient(`${containerUrl}?${sasToken}`);
      const containerClient = blobServiceClient.getContainerClient("");
  
      // Log the container URL and client creation for debugging
      console.log("Container Client URL:", containerClient.url);

      const uploadPromises = [];
  
      for (const file of files) {
        // Directly use the file name to upload it to the root of the container

        if (file.name) {
          console.log("Container Client --0-", containerClient);
          const blobClient = containerClient.getBlockBlobClient(`${file.name}`);
          console.log("URL ---", blobClient)
  
          console.log(`Uploading file: ${file.name}`);
          
          // Upload the file
          const uploadPromise = blobClient.uploadBrowserData(file, {
            blobHTTPHeaders: { blobContentType: file.type }, // Set content type for the file
          });
    
          uploadPromises.push(uploadPromise);
        }

      }
  
      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
  
      console.log("All files uploaded successfully!");
    } catch (err) {
      console.error("Error uploading files:", err.message);
    }
  }

  const handleAzureFileSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    setSelectedFiles(files);
  };
  
  // Handle file input change
  // document.getElementById("fileInput").addEventListener("change", async (event) => {
  //   const files = event.target.files;
  //   if (files.length === 0) {
  //     console.log("No files selected");
  //     return;
  //   }
  
  //   await uploadFilesToBlobStorage(files);
  // });


  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected");
      return;
    }

    setUploadingFileLoader(true)
    setUploadMsg("Please wait, we are uploading all the files")
    console.log("Files ready for upload:", selectedFiles);

    await uploadFilesToBlobStorage(selectedFiles);

    setUploadMsg("Files Uploaded SuccessFully, Now I am linking them.")

    const res = await linkAudioForCourse({ 
      courseId: courseId,
      blobName: containerName
    })


    setTimeout(() => {


      if (!res.success) {
        const data = res.response?.data ?? res;
        console.log(">>> Data ----", data)

        if (data.message) {
          setError(data.message);
        }
      } else {
        setExcelRowError(res.data.errorInFileLinking);
        setCourseCompleted(true)
        setCourseSuccess(false)
      }

        setUploadingFileLoader(false)
        setUploadMsg("");
        

    }, 500)


    // Add your upload logic here (e.g., to Azure Blob Storage)
    // alert(`${selectedFiles.length} files uploaded successfully!`);
  };

  const handleFileUpload = (event) => {
    setLoading(true);
    setError("")
    setExcelRowError([])
    setCourseSuccess(false)
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();


      const fileNameWithExtension = file.name; // Extract the file name with extension
      const courseName = fileNameWithExtension.split('.').slice(0, -1).join('.'); // Remove the extension

      reader.onload = async (e) => {
        const binaryData = e.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Use the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        console.log("JSON Data--", jsonData);
        const payload = {
          courseName,
          excelData: jsonData
        }

        const res = await uploadExcelForCourse(payload);
        console.log(">>> Res ----", res);
        setTimeout(() => {
          setLoading(false);

          if (!res.success) {
            const data = res.response?.data ?? res;
            console.log(">>> Data ----", data)

            if (data.message) {
              setError(data.message);
            }
          } else {
            setExcelRowError(res.data.excelRowError);
            setContainerUrl(res.data.blobContainerUrl)
            setCourseId(res.data.courseId)
            setContainerName(res.data.blobContainerName)
            setCourseSuccess(true)
          }
        }, 2000)

        // setFileData(jsonData);
        // console.log("Parsed Excel Data:", jsonData);
      };

      reader.readAsBinaryString(file);
    } else {
      setLoading(false)
      setError("Please Select a Excel course File")
    }
  };


  const handleEdit =() => {
    window.location = `${window.location.origin}/edit/${courseId}`;
  }
  return (
    <Layout>

    {courseCompleted ? <div  className="loader-container">

        <div> <CheckIcon color="success" /> </div>
        <div> All Steps Done for Course Upload, Go Ahead and review once. </div>
        <br />
        <div> <Button variant="outlined" color="info" onClick={handleEdit} >Edit Now </Button></div>

    </div>: <div className="excel-uploader">
      <h2>Upload an Excel File to Create Course</h2>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
      />
      {loading && <div className="loader-container">
          <h2>Please wait, we are currently processing the course content.</h2>
          <br />
          <CircularProgress  color="info"/>
          </div>
      }


      

      {
        !loading && error && <div className="error-container">
          <h2>{error}</h2>
        </div>
      }


      {!loading && !error && success &&  excelRowError.length ? <div className="loader-container">
          <h2>Course is created successfully but it have below issues</h2>
          <br />
          <ul style={{ textAlign: "left"}}>
            {
              excelRowError.map((excelData) => (<li>{excelData}</li>))
            }
          </ul>
        </div> :  !loading && success  && !error ?  <h2>Course is created successfully</h2>  : null
      }

      {/* <br /> */}

      {!loading && !error && success && 
         <div  className="loader-container">
           <h2>Now Upload Multiple Files</h2>
            <input
              type="file"
              multiple
              onChange={handleAzureFileSelect}
              style={{ margin: "10px 0" }}
            />  

            {uploadingFileLoader ? <div>
              <h2>{uploadMsg}</h2>
              <br />
              <CircularProgress  color="info"/>
              </div> : <button onClick={handleUpload} style={{ padding: "10px", cursor: "pointer" }}>
              Upload Files
            </button>}
        </div>
      }

      
    </div>}
    </Layout>

  );
};

export default ExcelUploader;
